import React from 'react';
import { Container, Typography, Link, Paper, Grid } from '@mui/material';
import './App.css'; // Ensure this contains the necessary styles

function Fixtures() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Fixtures (2023/2024)
                    </Typography>
                    <Typography variant="body1">
                        For match results and current league standings go to{' '}
                        <Link href="https://www.avonba.org/bristol-league" target="_blank" rel="noopener noreferrer">
                            Bristol League | Avon Badminton
                        </Link>
                    </Typography>
                    <Typography variant="h6" style={{ margin: '20px 0' }}>
                        All Fixtures
                    </Typography>
                    <Paper>
                        <iframe
                            src="https://docs.google.com/spreadsheets/d/1DMvs1jtrWzBK0HNkjCb2Oz6EzQVieqf4/edit?usp=sharing&ouid=111368016276647913839&rtpof=true&sd=true"
                            title="Fixtures"
                            width="100%"
                            height="600"
                            style={{ border: 'none' }}
                        ></iframe>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Fixtures;
